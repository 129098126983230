import React, { useEffect, useState, Suspense } from "react";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./css/main.css";

import { AuthContext } from "./context/auth-context";
import { AssistentContext } from "./context/assistent-context";
import { useAuth } from "./hooks/auth-hook";
import { useAssistent } from "./hooks/assistent-hook";

import Header from "./components/header/header";
import getAllSaleAccounts from "./api/bol/get-all-sale-accounts";
import getBearerToken from "./api/bol/get-bearer-token";

const Auth = React.lazy(() => import("./pages/Auth"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const Assortment = React.lazy(() => import("./pages/Assortment"));
const AssortmentAddArticle = React.lazy(() =>
  import("./pages/Assortment-add-article")
);
const AssortmentSkuSchema = React.lazy(() =>
  import("./pages/Assortment-sku-schema")
);
const AssortmentCategories = React.lazy(() =>
  import("./pages/Assortment-categories")
);
const BolSelectAccount = React.lazy(() => import("./pages/bol/Select-account"));
const BolAccountDashboard = React.lazy(() =>
  import("./pages/bol/account/Dashboard")
);
const BolOrdersOpen = React.lazy(() =>
  import("./pages/bol/account/orders/Open")
);
const BolOrdersOwnShippingMethod = React.lazy(() =>
  import("./pages/bol/account/orders/Own-shipping-method")
);
const BolOrdersBolShippingMethods = React.lazy(() =>
  import("./pages/bol/account/orders/Ship-with-bol-labels")
);
const BolOrdersBolShippingMethodsSummary = React.lazy(() =>
  import("./pages/bol/account/orders/Ship-with-bol-labels-summary")
);
const BolOrdersBolShippingMethodsConfirmed = React.lazy(() =>
  import("./pages/bol/account/orders/Ship-with-bol-labels-confirmed")
);
const BolOrdersOpenShipViaBolCom = React.lazy(() =>
  import("./pages/bol/account/orders/Open-ship-via-bol-com.js")
);
const BolOrdersOwnShippingMethodConfirmed = React.lazy(() =>
  import("./pages/bol/account/orders/Own-shipping-method-confirmed")
);
const BolOrdersHandled = React.lazy(() =>
  import("./pages/bol/account/orders/Handled.js")
);
const BolReturnsOpen = React.lazy(() =>
  import("./pages/bol/account/returns/Open.js")
);
const BolReturnsHandled = React.lazy(() =>
  import("./pages/bol/account/returns/Handled.js")
);
const BolAssortmentOffers = React.lazy(() =>
  import("./pages/bol/account/assortment/Offers")
);
const BolAssortmentNewOffer = React.lazy(() =>
  import("./pages/bol/account/assortment/New-offer")
);

const AdminDashboard = React.lazy(() => import("./admin/pages/Dasboard"));
const RegisteredUsers = React.lazy(() => import("./admin/pages/Users"));
const BolSaleAccounts = React.lazy(() =>
  import("./admin/pages/bol/Sale-accounts")
);

const App = () => {
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    (async () => {
      let result = await getAllSaleAccounts();
      setAccounts(result);
    })();
  }, []);

  const {
    userId,
    token,
    login,
    logout,
    username,
    firstName,
    surname,
    image,
    role,
  } = useAuth();

  const { assistent, setAssistentHandler } = useAssistent();

  let routes;
  if (!token) {
    routes = (
      <Switch>
        <Route path="/auth">
          <Auth />
        </Route>

        {/* {!token && <Redirect to="/auth" />} */}
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/" exact>
          <Dashboard />
        </Route>
        <Route path="/admin/dashboard" exact>
          <AdminDashboard />
        </Route>
        <Route path="/admin/users" exact>
          <RegisteredUsers />
        </Route>
        <Route path="/admin/bol/sale-accounts" exact>
          <BolSaleAccounts />
        </Route>
        <Route path="/assortment" exact>
          <Assortment />
        </Route>
        <Route path="/assortment/add-article" exact>
          <AssortmentAddArticle />
        </Route>
        <Route path="/assortment/sku-schema" exact>
          <AssortmentSkuSchema />
        </Route>
        <Route path="/assortment/categories" exact>
          <AssortmentCategories />
        </Route>
        <Route path="/bol/select-account" exact>
          <BolSelectAccount />
        </Route>
        <Route path="/bol/accounts/:account" exact>
          <BolAccountDashboard />
        </Route>
        <Route path="/bol/accounts/:account/orders/open" exact>
          <BolOrdersOpen />
        </Route>
        <Route path="/bol/accounts/:account/orders/own-shipping-method" exact>
          <BolOrdersOwnShippingMethod />
        </Route>
        <Route path="/bol/accounts/:account/orders/bol-shipping-labels" exact>
          <BolOrdersBolShippingMethods />
        </Route>
        <Route
          path="/bol/accounts/:account/orders/bol-shipping-labels/summary"
          exact
        >
          <BolOrdersBolShippingMethodsSummary />
        </Route>
        <Route
          path="/bol/accounts/:account/orders/bol-shipping-labels/confirmed"
          exact
        >
          <BolOrdersBolShippingMethodsConfirmed />
        </Route>
        <Route path="/bol/accounts/:account/orders/ship-via-bol-com" exact>
          <BolOrdersOpenShipViaBolCom />
        </Route>
        <Route
          path="/bol/accounts/:account/orders/own-shipping-method/confirmed-shipments"
          exact
        >
          <BolOrdersOwnShippingMethodConfirmed />
        </Route>
        <Route path="/bol/accounts/:account/orders/handled" exact>
          <BolOrdersHandled />
        </Route>
        <Route path="/bol/accounts/:account/returns/open" exact>
          <BolReturnsOpen />
        </Route>
        <Route path="/bol/accounts/:account/returns/handled" exact>
          <BolReturnsHandled />
        </Route>
        <Route path="/bol/accounts/:account/assortment/offers" exact>
          <BolAssortmentOffers />
        </Route>
        <Route path="/bol/accounts/:account/assortment/new-offer" exact>
          <BolAssortmentNewOffer />
        </Route>
        <Redirect to="/" />
      </Switch>
    );
  }

  return (
    <div className="App">
      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          token: token,
          userId: userId,
          login: login,
          logout: logout,
          username: username,
          firstName: firstName,
          surname: surname,
          image: image,
          role: role,
        }}
      >
        <AssistentContext.Provider
          value={{
            assistent: assistent,
            setAssistent: setAssistentHandler,
          }}
        >
          <Router>
            <Header />

            <Suspense
              fallback={
                <div className="center">
                  <div className="sweet-loading">
                    {/* <BarLoader
              css={override}
              size={2000}
              width={300}
              height={8}
              color={"maroon"}
              loading={true}
            /> */}
                  </div>
                </div>
              }
            >
              {routes}
            </Suspense>
          </Router>
        </AssistentContext.Provider>
      </AuthContext.Provider>
    </div>
  );
};

export default App;
