import { createContext } from "react";

export const AuthContext = createContext({
  isLoggedIn: false,
  userId: null,
  token: null,
  tokenExpirationDate: null,
  email: null,
  username: null,
  firstName: null,
  surname: null,
  role: null,
  login: () => {},
  logout: () => {},
});
