import React from "react";
import { Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../../css/header.css";

import GrandGroupLogoWhite from "../../images/grandgroup-logo-white.svg";
import Navigation from "./navigation";

const Header = () => {
  return (
    <Navbar className="main-nav grandHeader" expand="md">
      <NavLink to="/auth">
        <img src={GrandGroupLogoWhite} className="header-logo" alt="logo" />
      </NavLink>
      <Navigation />
    </Navbar>
  );
};

export default Header;
