const base64 = require("../../utils/base64");

const getBearerToken = async (id, password) => {
  let authToken = base64.encode64(`${id}:${password}`);
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ authToken: authToken }),
  };

  let response = await fetch(
    process.env.REACT_APP_ASSET_URL + `/credentials/bol/bearer-token`,
    requestOptions
  );
  let data = await response.json();
  return data;
  // .then((data) => {
  //   console.log(data)
  //   return data;
  // })
  // .then((tokenData) => {
  //   tokenData.forEach((element) => {
  //     element.tokenReceivedTime = new Date().getTime();
  //   });
  //   localStorage.setItem("tokenStates", JSON.stringify(tokenData));
  //   console.log(tokenData);
  //   return tokenData;
  // })
  // .catch((err) => {
  //   console.log(err);
  // });
};

export default getBearerToken;
