const encode64 = (input) => {
  // create a buffer
  const buff = Buffer.from(input, "utf-8");
  // decode buffer as Base64
  return buff.toString("base64");
};

const decode64 = (input) => {
  // create a buffer
  const buff = Buffer.from(input, "base64");
  // decode buffer as UTF-8
  return buff.toString("utf-8");
};

exports.encode64 = encode64;
exports.decode64 = decode64;
