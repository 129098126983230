import React, { useContext, useState } from "react";

import { AuthContext } from "../../context/auth-context";
import { AssistentContext } from "../../context/assistent-context";

import { Nav, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const Navigation = (props) => {
  const auth = useContext(AuthContext);
  const assist = useContext(AssistentContext);

  const logoutHandler = () => {
    auth.logout();
    assist.setAssistent(false)
    sessionStorage.removeItem('assistent');

  };

  console.log(auth);
  return (
    <Nav className="navigatieGrand">
      {auth.isLoggedIn && (
        <NavLink to="/" className="nav-link" exact>
          Dashboard
        </NavLink>
      )}
      {auth.isLoggedIn && (
        <NavDropdown title="Assortiment" id="assortmentDropDown">
          <NavDropdown.Item>
            <NavLink to="/assortment" className="nav-link" exact>
              Artikelen
            </NavLink>
          </NavDropdown.Item>
          <NavDropdown.Item>
            <NavLink to="/assortment/categories" className="nav-link" exact>
              Categorieën
            </NavLink>
          </NavDropdown.Item>
          <NavDropdown.Item>
            <NavLink to="/assortment/sku-schema" className="nav-link" exact>
              SKU Schema
            </NavLink>
          </NavDropdown.Item>
        </NavDropdown>
      )}

      {auth.isLoggedIn && (
        <NavDropdown title="Account" id="accountDropDown">
          <NavDropdown.Item>
            <NavLink className="nav-link" to={`/admin/dashboard`}>
              Admin dashboard
            </NavLink>
          </NavDropdown.Item>
          <NavDropdown.Item>
            <NavLink className="nav-link" to="" onClick={() => logoutHandler()}>
              Uitloggen
            </NavLink>
          </NavDropdown.Item>
        </NavDropdown>

        // <NavDropdown title="Account" id="accountDropDown">
        //   <NavDropdown.Item to="https://localhost:3000/#/admin/dashboard">
        //     <NavLink>Admin dashboard</NavLink>
        //   </NavDropdown.Item>
        //   <NavDropdown.Item to="">
        //     <NavLink onClick={() => logoutHandler()}>
        //       Uitloggen
        //       {/* <Link to="/">Uitloggen</Link> */}
        //     </NavLink>
        //   </NavDropdown.Item>
        // </NavDropdown>
      )}
    </Nav>
  );
};

export default Navigation;
