import { useState, useCallback, useEffect } from "react";

export const useAssistent = () => {
  const [assistent, setAssistent] = useState(false);

  const setAssistentHandler = useCallback((assist) => {
    setAssistent(assist);

    sessionStorage.setItem(
      "assistent",
      JSON.stringify({
        assistent: assist,
      })
    );
  }, []);

  useEffect(() => {
    const storedAssistent = JSON.parse(sessionStorage.getItem("assistent"));
    if (storedAssistent && storedAssistent.assistent) {
      setAssistent(storedAssistent);
    }
  }, [setAssistent]);

  return {
    assistent,
    setAssistentHandler,
  };
};
