const getAllSaleAccounts = async () => {
  let result = false;

  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  const responseData = await fetch(
    process.env.REACT_APP_ASSET_URL + "/credentials/bol/all-credentials",
    requestOptions
  );
  if (responseData.ok) {
    result = await responseData.json();
    return result.accounts;
  } else {
    let error = await responseData.json();
    // setErrorMessage(error.message);
    return error;
  }
};

export default getAllSaleAccounts;
